import React from 'react'
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import Icon from '../../atoms/Icon/Icon';
import { handleLink } from '../../../helpers/general';

import * as styles from './ContentfulBlockDuelImageCtas.module.css';

const ContentfulBlockDuelImageCtas = props => {
    const { 
        size,
        leftSide,
        rightSide,
        contained,
        ctaLayout
    } = props;

    const Wrap = ({children}) => contained ? <Container size={size}>{children}</Container> : <>{children}</>;

    return (
        <section className={`${styles.pageDuelCtas}`}>
            <Wrap>
                <AosInit classes={ctaLayout ? styles.imgWrapper : styles.ctaWrapper} attrs={leftSide.backgroundColour ? {style: {backgroundColor: leftSide.backgroundColour.hex, color: leftSide.backgroundColour.supportingTextColour}} : {}}>
                    {leftSide.ctaAsset && <img src={leftSide.ctaAsset.file.url} alt={leftSide.ctaLabel.split(' | ')[0]} />}
                    <a href={handleLink(leftSide.ctaUrl)}>&nbsp;</a>
                    <div className={styles.body}>
                        <h5>{leftSide.ctaLabel.split(' | ')[0]}</h5>
                        {leftSide.ctaLabel.split(' | ').length > 1 && (<p>{leftSide.ctaLabel.split(' | ')[1]}</p>)}
                        {leftSide.supportText && (<span className={styles.cta}>{leftSide.supportText} <Icon symbol="arrowRight" /></span>)}
                    </div>
                </AosInit>
                <AosInit classes={ctaLayout ? styles.imgWrapper : styles.ctaWrapper} attrs={rightSide.backgroundColour ? {style: {backgroundColor: rightSide.backgroundColour.hex, color: rightSide.backgroundColour.supportingTextColour}} : {}}>
                    {rightSide.ctaAsset && <img src={rightSide.ctaAsset.file.url} alt={rightSide.ctaLabel.split(' | ')[0]} />}
                    <a href={handleLink(rightSide.ctaUrl)}>&nbsp;</a>
                    <div className={styles.body}>
                        <h5>{rightSide.ctaLabel.split(' | ')[0]}</h5>
                        {rightSide.ctaLabel.split(' | ').length > 1 && (<p>{rightSide.ctaLabel.split(' | ')[1]}</p>)}
                        {rightSide.supportText && (<span className={styles.cta}>{rightSide.supportText} <Icon symbol="arrowRight" /></span>)}
                    </div>
                </AosInit>
            </Wrap>
        </section>
    )
}

export default ContentfulBlockDuelImageCtas;