import React from 'react'
import { get } from 'lodash';
import ContentfulImage from '../../atoms/ContentfulImage/ContentfulImage';
import ContentfulVideo from '../../atoms/ContentfulVideo/ContentfulVideo';
import ContentfulAnimation from '../../atoms/ContentfulAnimation/ContentfulAnimation';

const ContentfulMedia = ({ media }) => {
    const blockType = get(media, 'internal.type');
    
    switch (blockType) {
        case 'ContentfulImage':
            return <ContentfulImage {...media} />

        case 'ContentfulVideo':
            return <ContentfulVideo {...media} />

        case 'ContentfulAnimation':
            return <ContentfulAnimation {...media} />

        default:
            return null;
    }
}

export default ContentfulMedia;