import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import FeaturedTestimonials, { useStyles as styles } from '../../organisms/FeaturedTestimonials/FeaturedTestimonials';


const ContentfulBlockTestimonials = props => {
    const { 
        quotes,
    } = props;
    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    return (
        <AosInit>
            <FeaturedTestimonials useChildren={true} overrideStyles={mainStyles}>
                {quotes && quotes.map((testimonial, index) => (
                  <div key={index} className={styles.cardBody}>
                    <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/rider-logo.png" alt="Rider Logo" />
                    <p className={styles.title}>{testimonial.author}</p>
                    <p className={styles.description}>{testimonial.quoteFromReview.quoteFromReview}</p>
                  </div>
                ))}
            </FeaturedTestimonials>
        </AosInit>
    )
}

export default ContentfulBlockTestimonials;