import React from 'react'
import { get } from 'lodash';
import ContentfulBlock from '../../organisms/ContentfulBlock/ContentfulBlock';

import * as styles from './ContentfulBlockVisual.module.css';

const ContentfulBlockVisual = props => {
    const { 
        size,
        topBlocks,
        bottomBlocks,
    } = props;

    const backgroundType = get(props, 'background.internal.type', false);
    const backgroundImage = get(props, 'background.image.file.url', false);
    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);
    
    const mainStyles = {};

    if (backgroundType && (topBlocks || bottomBlocks) && backgroundType.startsWith('ContentfulImage')) {
        mainStyles.backgroundImage = `url(${backgroundImage})`;
    }

    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    return (
        <section className={`${styles.pageVisual} ${!topBlocks && !bottomBlocks ? styles.imageOnly : ''} ${'backgroundImage' in mainStyles ? styles.withBackgroundImage : ''}`} style={mainStyles}>
            {(!topBlocks && !bottomBlocks && backgroundType && backgroundType.startsWith('ContentfulImage')) && (
                <img src={backgroundImage} alt={get(props, 'background.image.altText', 'Forcite visual')} />
            )}

            {topBlocks && (
                <div className={styles.topBlocks}>
                    {topBlocks.map((block, blockIndex) => (
                        <ContentfulBlock key={blockIndex} block={block} size={size} />
                    ))}
                </div>
            )}

            {bottomBlocks && (
                <div className={styles.bottomBlocks}>
                    {bottomBlocks.map((block, blockIndex) => (
                        <ContentfulBlock key={blockIndex} block={block} size={size} />
                    ))}
                </div>
            )}
        </section>
    )
}

export default ContentfulBlockVisual;