import React from 'react';
import Slider from 'react-slick';
import './Testimonials.css';

function Testimonials() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  
    return (
      <div data-featuredtestimonials="true" className="FeaturedTestimonials-module--featuredContainer--4T2cH">
        <Slider {...settings}>
          <div>
            <div className="FeaturedTestimonials-module--cardBody--r8-6m">
              <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/rider-logo.png" alt="Rider Logo" />
              <p className="FeaturedTestimonials-module--title--rdwrl">Everything For The Man</p>
              <p className="FeaturedTestimonials-module--description--TMQtt">The helmet is certainly sleek and apparently ultra-light. But it’s what’s inside the helmet that gives it brains to protect yours</p>
            </div>
          </div>
          <div>
            <div className="FeaturedTestimonials-module--cardBody--r8-6m">
              <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/rider-logo.png" alt="Rider Logo" />
              <p className="FeaturedTestimonials-module--title--rdwrl">Motorcycle.com</p>
              <p className="FeaturedTestimonials-module--description--TMQtt">It’s essentially a motovlogger’s dream. Overall, as someone who rides motorcycles and talks about them on video, having something like the Forcite MK1 is nice</p>
            </div>
          </div>
          <div>
            <div className="FeaturedTestimonials-module--cardBody--r8-6m">
              <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/rider-logo.png" alt="Rider Logo" />
              <p className="FeaturedTestimonials-module--title--rdwrl">Aus Road Rider</p>
              <p className="FeaturedTestimonials-module--description--TMQtt">This helmet is smarter than the brain it’s trying to protect.</p>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
  
  export default Testimonials;