import React from "react";
import { useStaticQuery, graphql } from 'gatsby';
import Container from "../../atoms/Container/Container";
import VideoCard from '../../molecules/VideoCard/VideoCard'

import * as styles from './VideosGrid.module.css'

const VideosGrid = props => {
    const data = useStaticQuery(graphql`
        query {
            featuredVideo: contentfulVideo(isFeatured: {eq: true}, listOnVideosPage: {eq: true}) {
                title
                blurb {
                  blurb
                }
                videoUrl
                splashScreen {
                  file {
                    url
                  }
                }
            }
            allVideos: allContentfulVideo(
                sort: {fields: createdAt, order: DESC}
                filter: {isFeatured: {eq: false}, listOnVideosPage: {eq: true}}
            ) {
                nodes {
                  title
                  blurb {
                    blurb
                  }
                  videoUrl
                  splashScreen {
                    file {
                      url
                    }
                  }
                }
            }
        }
    `)
    const featuredVideo = data.featuredVideo;
    const allVideos = data.allVideos.nodes;

    return (
        <div className={styles.videosGrid}>
            <Container size={props.size}>
                {featuredVideo && (
                    <div className={styles.featuredVideo}>
                        <VideoCard isFeatured={true} {...featuredVideo} />
                    </div>
                )}
                {allVideos && (
                    <div className={styles.videoTiles}>
                        {allVideos.map((video, videoIndex) => (
                            <VideoCard key={`videoCard_${videoIndex}`} {...video} />
                        ))}
                    </div>
                )}
            </Container>
        </div>
    )
}

export default VideosGrid;