import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import * as styles from './ContentfulBlockColumns.module.css';

const ContentfulBlockColumns = props => {
    const { 
        size,
        column1, 
        column2, 
        column3,
    } = props;
    const backgroundImage = get(props, 'backgroundImage.image.file.url', false);
    const backgroundColour = get(props, 'backgroundColour.hex', false); 
    const textColour = get(props, 'backgroundColour.supportingTextColour', false); 

    const mainStyles = {};
    if (backgroundImage) {
        mainStyles['backgroundImage'] = `url(${backgroundImage})`;
    }
    if (backgroundColour) {
        mainStyles['backgroundColor'] = backgroundColour;
        mainStyles['color'] = textColour;
    }

    return (
        <section className={styles.pageColumns} style={mainStyles}>
            <AosInit>
                <Container size={size}>
                    {column1 && column1.raw && (<div className={styles.column}><ContentfulRichText raw={column1.raw} /></div>)}
                    {column2 && column2.raw && (<div className={styles.column}><ContentfulRichText raw={column2.raw} /></div>)}
                    {column3 && column3.raw && (<div className={styles.column}><ContentfulRichText raw={column3.raw} /></div>)}
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockColumns;