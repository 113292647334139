import React, { useState } from "react";
import YouTube from 'react-youtube';
import Dialog from '../Dialog/Dialog';

import * as styles from './Video.module.css'

const Video = props => {
    const [openModal, setOpenModal] = useState(false);
    const [videoObj, setVideoObj] = useState(null);
    const url = props.url;

    const initVideo = event => {
        setVideoObj(event.target);
    }

    const triggerModal = modal => {
        setOpenModal(modal);
        if (modal) {
            videoObj.playVideo();
        } else {
            videoObj.pauseVideo();
        }
    }
    
    if (url.indexOf('youtube.com') > -1) {
        const youTubeId = url.split('?v=')[1];

        return (
            <div className={styles.videoContainer} onClick={() => triggerModal(!openModal)} role="presentation">
                {props.splashScreen ? (
                    <>
                        <div className={styles.splashScreen}>
                            <img src={props.splashScreen} alt={props.title} />
                        </div>
                        <span className={styles.cover}>
                            <div className={styles.ytbtn}>
                                <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path className="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg>
                            </div>
                        </span>
                    </>
                ) : (
                    <>
                        <iframe src={`https://www.youtube-nocookie.com/embed/${youTubeId}?modestbranding=1`} title={process.env.GENERAL_PROJECT_NAME} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <span className={styles.cover}></span>
                    </>
                )}
                <Dialog open={openModal} size="lg" hideBtnCancel hideBtnOk>
                    <div className={styles.modalContainer}>
                        {/* <iframe src={`https://www.youtube-nocookie.com/embed/${youTubeId}?modestbranding=1&color=white&enablejsapi=1&origin=${process.env.SITE_URL}`} title={process.env.GENERAL_PROJECT_NAME} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                        <YouTube videoId={youTubeId} opts={{playerVars: {modestbranding: 1, origin: process.env.SITE_URL}}} onReady={(e) => initVideo(e)} />
                    </div>
                </Dialog>
            </div>
        )
    }

    return null;
}

export default Video;