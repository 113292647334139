import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import VideosGrid from '../../organisms/VideosGrid/VideosGrid';
import InstagramFeed from '../../organisms/InstagramFeed/InstagramFeed';
import KlaviyoSignUp from '../../organisms/KlaviyoSignUp/KlaviyoSignUp';
// Hard Coded Blocks
import BlockDetails from '../../organisms/BlockDetails/BlockDetails';
import DuelImage from '../../organisms/DuelImage/DuelImage';
import FeatureDetails from '../../organisms/FeatureDetails/FeatureDetails';
import PressWall from '../../organisms/PressWall/PressWall';
import DuelImageNexus from '../../organisms/DuelImageNexus/DuelImageNexus';
import DuelImageMK1S from '../../organisms/DuelImageMK1S/DuelImageMK1S';
import Testimonials from '../../organisms/Testimonials/Testimonials';
import ExplodeVideo from '../../organisms/ExplodeVideo/ExplodeVideo';
// Italian Blocks
import DuelImageNexusItalian from '../../organisms/DuelImageNexusItalian/DuelImageNexusItalian';
import BlockDetailsItalian from '../../organisms/BlockDetailsItalian/BlockDetailsItalian';
import DuelImageMK1SItalian from '../../organisms/DuelImageMK1SItalian/DuelImageMK1SItalian';
import FeatureDetailsItalian from '../../organisms/FeatureDetailsItalian/FeatureDetailsItalian';

const ContentfulBlockCodedComponent = props => {
    const component = get(props, 'component');

    switch (component) {
        case 'Videos list':
            return <AosInit><VideosGrid size={props.size} /></AosInit>

        case 'Instagram feed':
            return <AosInit><InstagramFeed /></AosInit>

        case 'Form Shortcode':
            return <AosInit><KlaviyoSignUp code={props.value} containerSize={props.size} /></AosInit>

            case 'Block Details':
                return <AosInit><BlockDetails /></AosInit>

            case 'Block Details IT':
                return <AosInit><BlockDetailsItalian /></AosInit>
    
            case 'Duel Image':
                return <AosInit><DuelImage /></AosInit>
    
            case 'Duel Image Nexus':
                return <AosInit><DuelImageNexus /></AosInit>

            case 'Duel Image Nexus IT':
                return <AosInit><DuelImageNexusItalian /></AosInit>

            case 'Duel Image MK1S':
                return <AosInit><DuelImageMK1S /></AosInit>

            case 'Duel Image MK1S IT':
                return <AosInit><DuelImageMK1SItalian /></AosInit>
    
            case 'Feature Details':
                return <AosInit><FeatureDetails /></AosInit>
    
            case 'Feature Details IT':
                return <AosInit><FeatureDetailsItalian /></AosInit>
    
            case 'Press Wall':
                return <AosInit><PressWall /></AosInit>
    
            case 'Explode Video':
                return <AosInit><ExplodeVideo /></AosInit>

            case 'Testimonials':
                return <AosInit><Testimonials /></AosInit>

        default:
            return null;
    }
}

export default ContentfulBlockCodedComponent;