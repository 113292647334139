// extracted by mini-css-extract-plugin
export var titleContainer = "Accordion-module--titleContainer--kMA40";
export var title = "Accordion-module--title--dO3em";
export var toggleContainer = "Accordion-module--toggleContainer--CW3x7";
export var body = "Accordion-module--body--dCzFY";
export var root = "Accordion-module--root--4uIVH";
export var open = "Accordion-module--open--S1-PM";
export var closeBody = "Accordion-module--closeBody--6Qzox";
export var withIcon = "Accordion-module--withIcon--yIHXu";
export var startIcon = "Accordion-module--startIcon--lIrFg";
export var endIcon = "Accordion-module--endIcon--ePR3h";
export var iconContainer = "Accordion-module--iconContainer--iKcz7";
export var withBackground = "Accordion-module--withBackground--IqSfk";