import React, { useEffect } from 'react';
import './BlockDetails.css';

function openTab(evt, tabName) {
    // Declare all variables
    var i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.querySelectorAll('.SpecsGroup-module--specGroupContainer--hhSQj');
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = 'none';
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.querySelectorAll('.SpecsGroup-module--groupName--tL5Si');
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].classList.remove('active');
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  document.getElementById(tabName).style.display = 'block';
  evt.currentTarget.classList.add('active');
}

function BlockDetails() {
    useEffect(() => {
      // Hide content of other tabs when the component mounts
      const tabContents = document.querySelectorAll('.SpecsGroup-module--specGroupContainer--hhSQj');
      tabContents.forEach(tabContent => {
        if (tabContent.id !== 'specifications') {
          tabContent.style.display = 'none';
        }
      });
  
      // Simulate a click event on the 'Specifications' tab when the component mounts
      const specificationsTabButton = document.querySelector('.SpecsGroup-module--groupName--tL5Si[onClick*="specifications"]');
      if (specificationsTabButton) {
        specificationsTabButton.click();
      }
    }, []);
  
    return (
    <section class="ContentfulBlockDetailList-module--pageList--MM7tu">
        <div class="Container-module--container--ZiuZl Container-module--large--tvzy7">
            <div>
                <div class="SpecsGroup-module--specificationTitleList--dHUzq">
                    <h4 class="SpecsGroup-module--groupName--tL5Si active" role="presentation" onClick={(event) => openTab(event, 'specifications')}>Specifications</h4>
                    <h4 class="SpecsGroup-module--groupName--tL5Si " role="presentation" onClick={(event) => openTab(event, 'electronics')}>Electronics</h4>
                </div>

                <div id="specifications" class="SpecsGroup-module--specGroupContainer--hhSQj SpecsGroup-module--active--i0n7t">
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Outer Shell Material</span>
                        <p>The MK1S Helmet shell is made from T-400 carbon fibre composite</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Premium Liner</span>
                        <p>3D formed foam throughout cheek pads and crown liner offers a contoured fit. New neck roll
                            design with extendable chin curtain creates tighter seal around the neck, significantly
                            reducing noise while providing better isolation for improved Audio performance. Premium
                            sweat wick Materials have been selected for durability, comfort and fit. This padding is
                            ideal for the premium minded customer, and riders who experience excess highway usage.</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Certification</span>
                        <p>ECE 22.05 (DOT for US customers)</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Ventilation</span>
                        <p>8 vent system with optimised channel airflow</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Visor</span>
                        <p>Quick release mechanism. Locking switch for high speed usage. Pinlock Max Vision 120 ready.
                        </p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Internal Sun Visor</span>
                        <p>UV400 rated retractable sun visor (ECE helmets only. Not included in DOT/US Helmets)</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Buckle</span>
                        <p>D-loop System</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Weight</span>
                        <p>1500g ± 50g (XL weight approx. 1630g)</p>
                    </div>
                </div>

                <div id="electronics" class="SpecsGroup-module--specGroupContainer--hhSQj SpecsGroup-module--active--i0n7t">
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Bluetooth Controller Battery life</span>
                        <p>Approx. 4 days on a single charge</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Audio</span>
                        <p>Removable 40mm Harman Kardon speaker drivers. Comfort fit speaker cavity.</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Camera</span>
                        <p>HD 1080P resolution | 30-60 FPS frame rate | H.264 encoding | ¼ inch Sony IMX Sensor |
                            Superior low light performance</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Charging</span>
                        <p>Approx. 1.5 hours to full charge via USB-C port</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Comms</span>
                        <p>Forcite <b>does not</b> provide long-range Bluetooth comms. Communication is managed via VOIP
                            applications or phone call. In-app VOIP communication functionality is in the pipeline for
                            2024.</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Connectivity</span>
                        <p>BT 5.0 supported</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Helmet Battery life</span>
                        <p>3-4 hours of runtime with consistent camera usage. With no recording and mobile app running
                            in background usage will exceed 7 hours +</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Camera Lens</span>
                        <p>All glass optics with 158 degree diagonal field of view</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Memory</span>
                        <p>Micro SD card compatible up to 1TB</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Microphones</span>
                        <p>Omni-directional dual microphones tuned for vocal clarity and wind reduction</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Savings</span>
                        <p>All helmets Auto-save footage at a selectable time interval via the app (5,10,20 minute save
                            periods)</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Sensors</span>
                        <p>Built-in 3DOF accelerometer for sleep mode detection</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Stabilisation</span>
                        <p>Minor electronic vibration stabilisation</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Peripheral LED Display</span>
                        <p>Diffused LED array for non-distracting peripheral alert animations. Ambient light sensors for
                            dynamic brightness adjustment.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
  );
}

export default BlockDetails;