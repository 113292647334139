import React from 'react'
import { Link } from 'gatsby'
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import Icon from '../../atoms/Icon/Icon';
import { handleLink } from '../../../helpers/general';

import * as styles from './ContentfulBlockPageLink.module.css';

const ContentfulBlockPageLink = props => {
    const { 
        size,
        mainText,
        subText,
        url,
        backgroundColour,
        backgroundImage
    } = props;

    const setStyles = {backgroundColor: backgroundColour.hex, color: backgroundColour.supportingTextColour};
    if (backgroundImage) {
        setStyles.backgroundImage = `url(${backgroundImage.file.url})`;
    }

    return (
        <section className={`${styles.pageLink}`} style={setStyles}>
            <AosInit>
                {url.startsWith('http') ? (
                    <a href={handleLink(url)}>&nbsp;</a>
                ) : (
                    <Link to={handleLink(url)} />
                )}
                <Container size={size}>
                    <div className='row'>
                        <div className={styles.text}>
                            <span className={styles.mainText}>{mainText}</span>
                            {subText !== '' && (
                                <span className={styles.subText}>{subText}</span>
                            )}
                        </div>
                        <div>
                            <div className={`${styles.arrow}`}><Icon symbol="lineArrow" /></div>
                        </div>
                    </div>
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockPageLink;