import React, { useEffect, useRef } from 'react';
import Container from '../../atoms/Container/Container'
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import * as styles from './InstagramFeed.module.css';
import "./Snapppt.css"

function InstagramFeed(props) {
  const snappptWidget = useRef();

  // Implementation of Snappt for Instagram feed.
  const loadSnapppt = (callback) => {
    const existingScript = document.getElementById('snapppt');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://snapppt.com/widgets/widget_loader/beda6e14-81f8-5106-fc42-25503fd9bd86/sauce_homepage.js';
      script.id = 'snapppt';

      const container = document.getElementById('snapppt-widget');
      if (container) {
        container.appendChild(script);
      }

      script.onload = () => { 
        if (callback) callback();
      };
    }
  };

  useEffect(() => {
    loadSnapppt();
  }, [snappptWidget]);
  
  return (
    <div data-instagramfeed className={styles.root}>
      <Container size="large">
        <h4>@FORCITEHELMETS <br/>
          <span>
            <ContentfulTranslationText keyName="followUs">
              Follow Us
            </ContentfulTranslationText>
          </span>
        </h4>
        <div>
          <div ref={snappptWidget} id="snapppt-widget"></div>
        </div>
      </Container>
    </div>
  );
}

export default InstagramFeed;