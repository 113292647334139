import React, { useEffect } from 'react';
import './DuelImageNexusItalian.css';

function DuelImageNexusItalian() {
  useEffect(() => {
    // Function to append a path to the href attribute
    function appendPath(selector, path) {
      const links = document.querySelectorAll(selector);

      links.forEach(link => {
        link.addEventListener('click', (e) => {
          e.preventDefault(); // Prevent the default link behavior

          const currentHref = window.location.href; // Get the current URL
          const newHref = currentHref + path; // Append the desired path

          window.location.href = newHref; // Redirect to the new URL
        });
      });
    }

    // Example usage: Append "about" to the href of all links with specified classes
    appendPath('.about-link', 'about/');
    appendPath('.nexus-link', 'mk1s-nexus/');
  }, []);

  return (
    <section className="ContentfulBlockDuelImageCtas-module--pageDuelCtas--Vr5Lr">
      <div className="ContentfulBlockDuelImageCtas-module--imgWrapper--YAYX1 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
        <img
          src="https://images.ctfassets.net/jhh5ec7i1gt8/3n8dfyug6eoSS7wS90wSc3/014b5310e6acec63874e61d5723a4fa6/About-us-Large-Square-Image.jpg"
          alt="About Forcite"
        />
        <a href="" className="about-link">&nbsp;</a>
        <div className="ContentfulBlockDuelImageCtas-module--body--9ib8L">
          <h5>Chi Siamo</h5>
          <p>La nostra storia</p>
        </div>
      </div>
      <div className="ContentfulBlockDuelImageCtas-module--imgWrapper--YAYX1 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
        <img
          src="https://images.ctfassets.net/jhh5ec7i1gt8/3PFAUZLOR3ZqggDy9rVcy4/8a6d83b4a0dc1f04c066517444c9c459/2022-Nexus-Promo-Hero.jpg"
          alt="HIGH SPEED MEETS HIGH FIDELITY"
        />
        <a href="" className="nexus-link">&nbsp;</a>
        <div className="ContentfulBlockDuelImageCtas-module--body--9ib8L">
          <h5>MK1S Nexus</h5>
          <p>Registra interesse</p>
        </div>
      </div>
    </section>
  );
}

export default DuelImageNexusItalian;