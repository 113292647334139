import React from 'react'
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import * as styles from './ContentfulBlockForm.module.css';

const ContentfulBlockForm = props => {
    const { 
        size,
        copy,
        // form
    } = props;

    return (
        <section className={styles.pageForm}>
            <AosInit>
                <Container size={size}>
                    {copy && copy.raw && (<ContentfulRichText raw={copy.raw} />)}
                    Work is needed here to utilise the form code - please inform devs of what page this is on for this to be completed
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockForm;