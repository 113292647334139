import React from 'react';
import { useRef, useEffect } from 'react';
import './ExplodeVideo.css';

function ExplodeVideo() {
    const videoRef = useRef(null);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.volume = 0; // Mute the video initially
        videoRef.current.play();
      }
    }, []);

  return (
    <section class="ContentfulBlockVisual-module--pageVisual--RexJh">
        <div class="ContentfulBlockVisual-module--topBlocks--uznpC">
            <section class="ContentfulBlockVideo-module--pageVideo--BNZF9">
                <div data-aos="fade-up" data-aos-duration="1000" class="aos-init aos-animate">
                    <div class="Container-module--container--ZiuZl Container-module--large--tvzy7">
                        <div class="ContentfulBlockVideo-module--video--58TIV">
                            <video id="vid" ref={videoRef} poster="https://images.ctfassets.net/23pjxs5nkiyo/6WZvLiuJ5znC5DRYNPnTFV/f34249d38932360743d9b530d2bc8397/Forcite_MKS1_UPDATE_00509_Xplode_Loop_FirstFrame_02220329.png" preload="auto" autoplay muted loop>
                                <source src="https://videos.ctfassets.net/23pjxs5nkiyo/6gq4e9G2xo8auZqbEg14XS/3244ba6953f8f16d2d44e7b062f79429/Forcite_MKS1_UPDATE_00509_Xplode_Loop_20220329.mp4" type="video/mp4" />Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="ContentfulBlockVisual-module--bottomBlocks--PG5ta"></div>
    </section>
    );
}

export default ExplodeVideo;