import React from 'react'
import { get } from 'lodash';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText'
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon'

import * as styles from './ContentfulBlockFullScreenHero.module.css';
import { dataLayerPush, handleLink } from '../../../helpers/general';
import ContentfulVideo from '../../atoms/ContentfulVideo/ContentfulVideo';

const ContentfulBlockFullScreenHero = props => {
    const { 
        size,
        content, 
        callToActions, 
        configuration,
        background
    } = props;
    
    const backgroundColour = get(props, 'backgroundColour.hex', false); 
    const textColour = get(props, 'backgroundColour.supportingTextColour', false); 
    let backgroundVideo = false;

    const mainStyles = {};
    if (background && background.internal.type === 'ContentfulImage') {
        mainStyles['backgroundImage'] = `url(${background.image.file.url})`;
        mainStyles['backgroundPosition'] = background.focus;
    }
    if (background && background.internal.type === 'ContentfulVideo') {
        backgroundVideo = background;
    }
    if (backgroundColour) {
        mainStyles['backgroundColor'] = backgroundColour;
        mainStyles['color'] = textColour;
    }

    const configurationKey = configuration.replace(/\s/g, '').toLowerCase();
    
    return (
        <section className={`${styles.pageHeroFull} ${styles[configurationKey]}`} style={mainStyles}>
            {(backgroundVideo && configurationKey === 'centerscreen') && (
                <div className={styles.videoContain}>
                    <ContentfulVideo {...backgroundVideo} />
                </div>
            )}
            <Container size={size}>
                {(backgroundVideo && configurationKey === 'bottomofscreen') && (
                    <div className={styles.videoContain}>
                        <ContentfulVideo {...backgroundVideo} />
                    </div>
                )}
                <div className={styles.contentBlock}>
                    <div className={styles.contentWrap}>
                        {content && (
                            <ContentfulRichText raw={content.raw} />
                        )}
                    </div>
                    {callToActions && (
                        <div className={styles.ctaWrap}>
                            {callToActions.map((cta, ctaIndex) => (
                                <Button key={ctaIndex} level={ctaIndex === 0 ? 'primary' : 'secondary'} alternate={ctaIndex === 1} href={cta.ctaUrl} onClick={cta.turnOnEventTracking ? (e) => {e.preventDefault(); dataLayerPush(cta.eventTrackingKey, {name: cta.eventTrackingName, content: cta.eventTrackingContent}, null, handleLink(cta.ctaUrl))} : null}>
                                    {cta.ctaLabel}
                                    {ctaIndex === 1 && (
                                        <Icon symbol="subscribeArrowRight" />
                                    )}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </section>
    )
}

export default ContentfulBlockFullScreenHero;