import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import Icon from '../../atoms/Icon/Icon'
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText'

import * as styles from './ContentfulBlockFeatureIcons.module.css';

const ContentfulBlockFeatureIcons = props => {
    const { 
        size,
        compactDesignIcon,
        eceIcon,
        featherIcon,
        techIcon
    } = props;
    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    } else {
        mainStyles.color = 'inherit';
    }

    return (
        <section className={styles.pageFeatureIcons} style={mainStyles}>
            <Container size={size}>
                <AosInit classes={styles.featureWrapper} attrs={{'data-aos-duration': 400}}>
                    <div className={styles.featureImg}>
                        {/* <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/ece.png" alt="ECE" /> */}
                        {eceIcon.heading.indexOf('ECE') > -1 && (<Icon symbol="featureEce" />)}
                        {eceIcon.heading.indexOf('DOT') > -1 && (<Icon symbol="featureDot" />)}
                    </div>

                    <div className={styles.featureTitleContainer}><p className={styles.featureTitle}>{eceIcon.heading}</p></div>
                    <div className={styles.featureDesc}><ContentfulRichText raw={eceIcon.copy.raw} /></div>
                </AosInit>

                <AosInit classes={styles.featureWrapper} attrs={{'data-aos-duration': 400, 'data-aos-delay': 100}}>
                    <div className={styles.featureImg}>
                        {/* <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/compact-design.png" alt="Compact design" /> */}
                        <Icon symbol="featureCompactDesign" />
                    </div>

                    <div className={styles.featureTitleContainer}><p className={styles.featureTitle}>{compactDesignIcon.heading}</p></div>
                    <div className={styles.featureDesc}><ContentfulRichText raw={compactDesignIcon.copy.raw} /></div>
                </AosInit>

                <AosInit classes={styles.featureWrapper} attrs={{'data-aos-duration': 400, 'data-aos-delay': 200}}>
                    <div className={styles.featureImg}>
                        {/* <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/ultra-lightweight.png" alt="Ultra Lightweight" /> */}
                        <Icon symbol="featureFeather" />
                    </div>

                    <div className={styles.featureTitleContainer}><p className={styles.featureTitle}>{featherIcon.heading}</p></div>
                    <div className={styles.featureDesc}><ContentfulRichText raw={featherIcon.copy.raw} /></div>
                </AosInit>

                <AosInit classes={styles.featureWrapper} attrs={{'data-aos-duration': 400, 'data-aos-delay': 300}}>
                    <div className={styles.featureImg}>
                        {/* <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/smart-features.png" alt="Smart Features" /> */}
                        <Icon symbol="featureTech" />
                    </div>

                    <div className={styles.featureTitleContainer}><p className={styles.featureTitle}>{techIcon.heading}</p></div>
                    <div className={styles.featureDesc}><ContentfulRichText raw={techIcon.copy.raw} /></div>
                </AosInit>
            </Container>
        </section>
    )
}

export default ContentfulBlockFeatureIcons;