import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/organisms/Layout/Layout';
import ContentfulRichText from '../components/atoms/ContentfulRichText/ContentfulRichText';
import ContentfulBlockHero from '../components/molecules/ContentfulBlockHero/ContentfulBlockHero';
import ContentfulBlockFullScreenHero from '../components/molecules/ContentfulBlockFullScreenHero/ContentfulBlockFullScreenHero';
import ContentfulBlock from '../components/organisms/ContentfulBlock/ContentfulBlock';
import Container from '../components/atoms/Container/Container';
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
import ContentfulContext from '../context/ContentfulProvider';
import { get } from 'lodash';

const ContentfulPage = ({ data }) => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    const page = (data && data.page) || false;
    const pageTitle = (page && ((page.hero && page.hero.headline) || page.metaTitle || page.name)) || '';
    const heroBlock = (page && page.hero && page.hero.internal && page.hero.internal.type) || null;
    const heroType = (page && page.hero && page.hero.heroType) || null;
    const detailedPage = ((heroBlock === 'ContentfulBlockHero' && (heroType === 'Image and Text' || heroType === 'Image Only')) || heroBlock === 'ContentfulBlockFullScreenHero');
    const containerSize = (detailedPage || page.wideCopyBlock) ? "large" : 'medium';
    const heroComponent = (type, object) => {
        switch (type) {
            case 'ContentfulBlockHero':
              return <ContentfulBlockHero {...object} size={containerSize} />;
            case 'ContentfulBlockFullScreenHero':
              return <ContentfulBlockFullScreenHero {...object} size="large" />;
            default:
              return null;
        }
    }

    return (
        <Layout noPadding={detailedPage && true} headerStyle={detailedPage && 'transparent'} seo={{title: pageTitle, description: page.metaDescription || ''}}>
            {!detailedPage && (
              <Container size={containerSize}>
                <Breadcrumbs crumbs={[{link: '/', label: get(translations, 'home.title', 'Home') }, {label: pageTitle}]} />
              </Container>
            )}
            {heroComponent(heroBlock, (page && page.hero) || {})}
            <Container size={containerSize}>
              {/* {detailedPage && (<Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label: pageTitle}]} />)} */}
              {page.copy && (
                <ContentfulRichText raw={page.copy.raw} />
              )}
            </Container>
            {page.blocks && page.blocks.map((block, blockKey) => (
                <ContentfulBlock key={blockKey} block={block} size={containerSize} />
            ))}
        </Layout>
    );
};

ContentfulPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default ContentfulPage;

export const query = graphql`
    query ContentfulPageByID($contentful_id: String!) {
        page: contentfulPageContent(contentful_id: { eq: $contentful_id }) {
            name
            slug
            hero {
              ... on ContentfulBlockHero {
                internal {
                  type
                }
                heroType
                headline
                desktopImage {
                  image {
                    file {
                      url
                    }
                  }
                }
                mobileImage {
                  image {
                    file {
                      url
                    }
                  }
                }
                submenu {
                    menuItems {
                      title
                      url
                    }
                }
              }
              ... on ContentfulBlockFullScreenHero {
                internal {
                  type
                }
                background {
                  ... on ContentfulImage {
                    id
                    name
                    altText
                    focus
                    image {
                      file {
                        url
                      }
                      title
                    }
                    internal {
                      type
                    }
                  }
                  ... on ContentfulVideo {
                    id
                    title
                    blurb {
                      blurb
                    }
                    videoUrl
                    rawVideoFile {
                      file {
                        url
                      }
                      title
                    }
                    splashScreen {
                      file {
                        url
                      }
                      title
                    }
                    internal {
                      type
                    }
                  }
                }
                backgroundColour {
                  hex
                  supportingTextColour
                }
                content {
                  raw
                }
                callToActions {
                  ctaLabel
                  ctaUrl
                  turnOnEventTracking
                  eventTrackingKey
                  eventTrackingName
                  eventTrackingContent
                }
                configuration
              }
            }
            wideCopyBlock
            copy {
                raw
            }
            blocks {
                ... on ContentfulBlockCarousel {
                  backgroundColour {
                    hex
                    supportingTextColour
                  }
                  carouselType
                  hideImageSlidesTitle
                  slides {
                    ... on ContentfulBlockSideCopy {
                      headline
                      copy {
                        raw
                      }
                      asset {
                        ... on ContentfulImage {
                          id
                          name
                          altText
                          image {
                            file {
                              url
                            }
                            title
                          }
                          internal {
                            type
                          }
                        }
                        ... on ContentfulVideo {
                          id
                          title
                          blurb {
                            blurb
                          }
                          videoUrl
                          rawVideoFile {
                            file {
                              url
                            }
                            title
                          }
                          splashScreen {
                            file {
                              url
                            }
                            title
                          }
                          internal {
                            type
                          }
                        }
                      }
                      orientation
                      internal {
                        type
                      }
                    }
                    ... on ContentfulImage {
                      id
                      name
                      altText
                      image {
                        file {
                          url
                        }
                        title
                      }
                      internal {
                        type
                      }
                    }
                  }
                  title
                  slidesToDisplay
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockCodedComponent {
                  component
                  value
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockColumns {
                  column1 {
                    raw
                  }
                  column2 {
                    raw
                  }
                  column3 {
                    raw
                  }
                  backgroundColour {
                    hex
                    supportingTextColour
                  }
                  backgroundImage {
                    image {
                      file {
                        url
                      }
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockCopy {
                  copy {
                    raw
                  }
                  separator
                  textSize
                  padded
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockFeatureIcons {
                  backgroundColour {
                    hex
                    supportingTextColour
                  }
                  compactDesignIcon {
                    heading
                    copy {
                      raw
                    }
                  }
                  eceIcon {
                    heading
                    copy {
                      raw
                    }
                  }
                  featherIcon {
                    heading
                    copy {
                      raw
                    }
                  }
                  techIcon {
                    heading
                    copy {
                      raw
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockReviews {
                  backgroundColour {
                    hex
                    supportingTextColour
                  }
                  title
                  supportingImage {
                    file {
                      url
                    }
                  }
                  reviews {
                    author
                    review {
                      raw
                    }
                    location
                    bike
                    riderType
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockPageLink {
                  mainText
                  subText
                  url
                  backgroundColour {
                    hex
                    supportingTextColour
                  }
                  backgroundImage {
                    file {
                      url
                    }
                  }
                  internal {
                    type
                  }
                }
                ... on ContentfulBlockSideCopy {
                  headline
                  copy {
                    raw
                  }
                  internal {
                    type
                  }
                  asset {
                    ... on ContentfulImage {
                      id
                      name
                      altText
                      image {
                        file {
                          url
                        }
                        title
                      }
                      internal {
                        type
                      }
                    }
                    ... on ContentfulVideo {
                      id
                      title
                      blurb {
                        blurb
                      }
                      videoUrl
                      rawVideoFile {
                        file {
                          url
                        }
                        title
                      }
                      splashScreen {
                        file {
                          url
                        }
                        title
                      }
                      internal {
                        type
                      }
                    }
                  }
                  orientation
                  widths
                  backgroundColour {
                    hex
                    supportingTextColour
                  }
                }
                ... on ContentfulBlockVisual {
                  background {
                    internal {
                      type
                    }
                    image {
                      file {
                        url
                      }
                    }
                  }
                  backgroundColour {
                    hex
                    supportingTextColour
                  }
                  topBlocks {
                    ... on ContentfulBlockColumns {
                      column1 {
                        raw
                      }
                      column2 {
                        raw
                      }
                      column3 {
                        raw
                      }
                      backgroundColour {
                        hex
                        supportingTextColour
                      }
                      backgroundImage {
                        image {
                          file {
                            url
                          }
                        }
                      }
                      internal {
                        type
                      }
                    }
                    ... on ContentfulBlockCopy {
                      copy {
                        raw
                      }
                      internal {
                        type
                      }
                    }
                    ... on ContentfulBlockFeatureIcons {
                      compactDesignIcon {
                        heading
                        copy {
                          raw
                        }
                      }
                      eceIcon {
                        heading
                        copy {
                          raw
                        }
                      }
                      featherIcon {
                        heading
                        copy {
                          raw
                        }
                      }
                      techIcon {
                        heading
                        copy {
                          raw
                        }
                      }
                      internal {
                        type
                      }
                    }
                  }
                  bottomBlocks {
                    ... on ContentfulBlockColumns {
                      column1 {
                        raw
                      }
                      column2 {
                        raw
                      }
                      column3 {
                        raw
                      }
                      backgroundColour {
                        hex
                        supportingTextColour
                      }
                      backgroundImage {
                        image {
                          file {
                            url
                          }
                        }
                      }
                      internal {
                        type
                      }
                    }
                    ... on ContentfulBlockCopy {
                      copy {
                        raw
                      }
                      internal {
                        type
                      }
                    } 
                  }
                  internal {
                    type
                  }
                }
            }
            metaTitle
            metaDescription
            metaCanonicalLink
        }
    }
`;

/*
                ... on ContentfulBlockForm {
                  form
                  copy {
                    raw
                  }
                  internal {
                    type
                  }
                }

                ... on ContentfulBlockVideo {
                  poster {
                    file {
                      url
                    }
                  }
                  youTubeId
                  title
                  internal {
                    type
                  }
                }
*/