import React from 'react'
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';

import * as styles from './ContentfulBlockCopy.module.css';

const ContentfulBlockCopy = props => {
    const { 
        size,
        copy,
        textSize,
        separator,
        padded
    } = props;

    return (
        <section className={`${styles.pageCopy} ${padded ? styles.padded : ''} ${textSize ? styles[`textSize${textSize}`] : ''} ${(separator && separator !== 'None') ? styles[`separator${separator.replace(' ', '')}`] : ''}`}>
            <AosInit>
                <Container size={size}>
                    <ContentfulRichText raw={copy.raw} />
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockCopy;