import React from 'react'
import Container from '../../atoms/Container/Container'

import * as styles from './KlaviyoSignUp.module.css'

const KlaviyoSignUp = ({ code, containerSize }) => {
  
  return (
    <Container size={containerSize}>
      <div
        className={styles.form}
        dangerouslySetInnerHTML={{
        __html: code
        }}
      />
    </Container>
  )
}

export default KlaviyoSignUp