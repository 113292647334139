import React from 'react'
import { get } from 'lodash';
import Container from '../../atoms/Container/Container';
import SpecsGroup from '../../organisms/SpecsGroup/SpecsGroup';

import * as styles from './ContentfulBlockDetailList.module.css';

const ContentfulBlockList = props => {

    const {size} = props;

    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    return (
        <section className={`${styles.pageList}`} style={mainStyles}>
            <Container size={size}>
                <SpecsGroup specs={props.tabs} />
            </Container>
        </section>
    )
}

export default ContentfulBlockList;