import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import ContentfulMedia from '../../organisms/ContentfulMedia/ContentfulMedia';

import * as styles from './ContentfulBlockSideCopy.module.css';

const ContentfulBlockSideCopy = props => {
    const { 
        size,
        headline,
        copy,
        // image: {file: {url: image}},
        asset,
        orientation,
        widths
    } = props;

    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    let configuration = 'even';
    if (widths === 'Larger image section') configuration = 'largerImage';
    if (widths === 'Larger text section') configuration = 'largerText';
    
    return (
        <section className={`${styles.pageSideCopy} ${orientation ? styles[`orientation${orientation.replace('Image ', '')}`] : styles.orientationleft} ${styles[configuration]}`} style={mainStyles}>
            <AosInit>
                <Container size={size}>
                    <div className={styles.content}>
                        <h4>{headline}</h4>
                        {copy && copy.raw && (<ContentfulRichText raw={copy.raw} />)}
                    </div>
                    <div className={styles.asset}>
                        <ContentfulMedia media={asset} />
                        {/* <img src={image} alt={headline} /> */}
                    </div>
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockSideCopy;