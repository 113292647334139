import React, { useEffect } from 'react';
import './DuelImage.css';

function DuelImage() {
  useEffect(() => {
    // Function to append a path to the href attribute
    function appendPath(selector, path) {
      const links = document.querySelectorAll(selector);

      links.forEach(link => {
        link.addEventListener('click', (e) => {
          e.preventDefault(); // Prevent the default link behavior

          const currentHref = window.location.href; // Get the current URL
          const newHref = currentHref + path; // Append the desired path

          window.location.href = newHref; // Redirect to the new URL
        });
      });
    }

    // Example usage: Append "about" to the href of all links with specified classes
    appendPath('.about-link', 'about/');
    appendPath('.master-dynamic-link', 'master-and-dynamic-speaker-accessory/');
  }, []);

  return (
    <section className="ContentfulBlockDuelImageCtas-module--pageDuelCtas--Vr5Lr">
      <div className="ContentfulBlockDuelImageCtas-module--imgWrapper--YAYX1 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
        <img
          src="https://images.ctfassets.net/jhh5ec7i1gt8/3n8dfyug6eoSS7wS90wSc3/014b5310e6acec63874e61d5723a4fa6/About-us-Large-Square-Image.jpg"
          alt="About Forcite"
        />
        <a href="" className="about-link">&nbsp;</a>
        <div className="ContentfulBlockDuelImageCtas-module--body--9ib8L">
          <h5>About Forcite</h5>
          <p>Our Story</p>
        </div>
      </div>
      <div className="ContentfulBlockDuelImageCtas-module--imgWrapper--YAYX1 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
        <img
          src="https://images.ctfassets.net/jhh5ec7i1gt8/5HO6U4z4OLpIuKn8gSZ3tq/f1d54933f2d0c277ec94da75f711f109/Master-and-Dynamic-Speaker-Title.jpg"
          alt="HIGH SPEED MEETS HIGH FIDELITY"
        />
        <a href="" className="master-dynamic-link">&nbsp;</a>
        <div className="ContentfulBlockDuelImageCtas-module--body--9ib8L">
          <h5>HIGH SPEED MEETS HIGH FIDELITY</h5>
          <p>Discover the all new Forcite x Master &amp; Dynamic collaboration</p>
        </div>
      </div>
    </section>
  );
}

export default DuelImage;