import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import { get } from 'lodash';
import { useWindowDimensions, handleLink } from '../../../helpers/general'
import Container from '../../atoms/Container/Container';
import Icon from '../../atoms/Icon/Icon';

import * as styles from './ContentfulBlockHero.module.css';

const ContentfulBlockHero = props => {
    const { width } = useWindowDimensions();
    const { 
        size,
        heroType, 
        headline, 
        submenu,
    } = props;
    const desktopImage = get(props, 'desktopImage.image.file.url', false); 
    const mobileImage = get(props, 'mobileImage.image.file.url', desktopImage); 
    const [backgroundImage, setBackgroundImage] = useState(null);

    const [activeNav, setActiveNav] = useState(null);
    const  clickNav = (e, url) => {
        if (url === window.location.pathname) {
            e.preventDefault();
        }
        setActiveNav(!activeNav)
    }

    const subMenuRef = useRef();
    const [showScrolled, setShowScrolled] = useState(false);
    const handleScroll = useCallback(() => {
        if (submenu) {
            if (window.scrollY > (subMenuRef.current.offsetTop - 38) && !showScrolled) {
                setShowScrolled(true);
            } else if (window.scrollY <= (subMenuRef.current.offsetTop - 38) && showScrolled) {
                setShowScrolled(false);
            }
        }
    }, [submenu, showScrolled, setShowScrolled]);

    const handleHeroImage = useCallback(() => {
        // console.log("Calculated Width: ", width);
        const newBg = width >= 1024 ? desktopImage : mobileImage;
        if (newBg !== backgroundImage) {
            setBackgroundImage(newBg);
        }
    }, [width, backgroundImage, mobileImage, desktopImage]);

    useEffect(() => {
        handleHeroImage();
    }, [handleHeroImage]);
    
    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [showScrolled, handleScroll]);

    if (heroType === 'Image and Text' || heroType === 'Image Only') {
        return (
            <section className={`${styles.pageHeroImage} ${submenu ? styles.withSubmenu : ''}`} style={{backgroundImage: `url(${backgroundImage})`}}>
                <Container size={size}>
                    {headline && (
                        <h1>{headline}</h1>
                    )}
                </Container>
                {submenu && (
                    <div className={`${styles.heroSubmenu} ${activeNav ? styles.active : ''}`} ref={subMenuRef}>
                        <Container size={size}>
                            <div className={styles.inner}>
                                {submenu.menuItems.map((item, itemIndex) => {
                                    if (item.url.startsWith('http')) {
                                        return <a key={itemIndex} href={handleLink(item.url)} 
                                        onClick={(e) => clickNav(e, item.url)}>
                                            {item.title}
                                            <Icon symbol="arrowDown" />
                                        </a>
                                    } else {
                                        return <Link key={itemIndex} to={handleLink(item.url)} 
                                        onClick={(e) => clickNav(e, item.url)}>
                                            {item.title}
                                            <Icon symbol="arrowDown" />
                                        </Link>
                                    }
                                })}
                            </div>
                        </Container>
                        <div className={`${styles.stickyTabs} ${showScrolled ? styles.stickyShow : ''}`}>
                            <Container size={size}>
                                <div className={styles.contentBlur}></div>
                                <div className={styles.innerSticky}>
                                    {submenu.menuItems.map((item, itemIndex) => {
                                        if (item.url.startsWith('http')) {
                                            return <a key={itemIndex} href={handleLink(item.url)}>{item.title}</a>
                                        } else {
                                            return <Link key={itemIndex} to={handleLink(item.url)}>{item.title}</Link>
                                        }
                                    })}
                                </div>
                            </Container>
                        </div>
                    </div>
                )}
            </section>
        )
    } else if (heroType === 'Animation') {
        return (
            <></>
        )
    } else if (heroType === 'Map') {
        return (
            <></>
        )
    } else {
        return (
            <section className={styles.pageHero}>
                <Container size={size}>
                    <h1 className='h3'>{headline}</h1>
                </Container>
            </section>
        )
    }
}

export default ContentfulBlockHero;