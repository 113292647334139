import React from 'react';
import Slider from '../../atoms/Slider/Slider';
import * as styles from './FeaturedTestimonials.module.css';
// import exampleTestimonials from './example.json';

function FeaturedTestimonials(props) {
  const sliderSettings = {
    lazyLoad: true,
    arrows: false,
    fade: true,
    // autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    swipeToSlide: true,
    touchMove: true,
    slidesToShow: 1,
    responsive:[],
  }

  const overrideStyles = 'overrideStyles' in props ? props.overrideStyles : {}

  if ('testimonials' in props || 'useChildren' in props) {
    return (
      <div data-featuredtestimonials className={styles.featuredContainer} style={overrideStyles}>
        <div className={styles.wrapper}>
          <Slider {...sliderSettings}>
            {'useChildren' in props ? (
              props.children
            ) : (
              <>
                {props.testimonials && props.testimonials.map((testimonial, index) => (
                  <div key={index} className={styles.cardBody}>
                    <img src="https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/rider-logo.png" alt="Rider Logo" />
                    <p className={styles.title}>{testimonial.title}</p>
                    <p className={styles.description}>{testimonial.content}</p>
                  </div>
                ))}
              </>
            )}
          </Slider>
        </div>
      </div>
    );
  } else {
    return null
  }
}
export const useStyles = styles;
export default FeaturedTestimonials;