import React from 'react';
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';
import LineArrow from '../../atoms/Icon/lineArrow';
import Slider from '../../atoms/Slider/Slider';
import * as styles from './FeaturedReviews.module.css';
// import exampleReviews from './example.json';

function SliderNextArrow(props) {
  const { onClick } = props;
  return (
    <div className={`${styles.arrowSlider} ${styles.arrowNext}`} role='presentation' onClick={onClick}>
      <div className={`${styles.arrow}`}><LineArrow/></div>
    </div>
  );
}

function SliderPrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={`${styles.arrowSlider} ${styles.arrowPrev}`} role='presentation' onClick={onClick}>
      <div className={`${styles.arrow}`}><LineArrow/></div>
    </div>
  );
}

function FeaturedReviews(props) {
  const sliderSettings = {
    lazyLoad: true,
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    swipeToSlide: true,
    touchMove: true,
    slidesToShow: 1,
    prevArrow: <SliderPrevArrow />,
    nextArrow: <SliderNextArrow />,
    responsive:[],
    fade: true
  }

  const overrideStyles = 'overrideStyles' in props ? props.overrideStyles : {}

  if ('reviews' in props || 'useChildren' in props) {
    return (
      <div data-featuredreviews className={styles.featuredContainer} style={overrideStyles}>
        <div className={styles.wrapper}>
          <div className={styles.sliders}>
            <p className={styles.title}>
              <ContentfulTranslationText keyName="reviews">
                Reviews
              </ContentfulTranslationText>
            </p>
            {'title' in props && (
              <h5>{props.title}</h5>
            )}
            <Slider {...sliderSettings}>
              {'useChildren' in props ? (
                props.children
              ) : (
                <>
                  {props.reviews && props.reviews.map((review, index) => (
                    <div key={index} className={styles.cardBody}>
                      <p className={styles.description}>{review.content}</p>
                      {review.name && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="name">Name</ContentfulTranslationText>:</strong> {review.name}</p>)}
                      {review.location && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="location">Location</ContentfulTranslationText>:</strong> {review.location}</p>)}
                      {review.bike && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="bike">Bike</ContentfulTranslationText>:</strong> {review.bike}</p>)}
                      {review.rider_type && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="riderType">Rider type</ContentfulTranslationText>:</strong> {review.rider_type}</p>)}
                    </div>
                  ))}
                </>
              )}
            </Slider>
          </div>

          <div className={styles.imageSection}>
              <img src={'supportingImage' in props ? props.supportingImage : "https://cdn11.bigcommerce.com/s-7pz9af8l5d/product_images/uploaded_images/rectangle-111.png"} alt="community" />
            </div>
        </div>
      </div>
    );
  } else {
    return null
  }
}

export const useStyles = styles;
export default FeaturedReviews;