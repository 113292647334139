import React from 'react'
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import ContentfulMedia from '../../organisms/ContentfulMedia/ContentfulMedia';

import * as styles from './ContentfulBlockVideo.module.css';

const ContentfulBlockVideo = props => {
    const { 
        size,
        video
    } = props;

    return (
        <section className={styles.pageVideo}>
            <AosInit>
                <Container size={size}>
                    <div className={styles.video}>
                        <ContentfulMedia media={video} />
                    </div>
                </Container>
            </AosInit>
        </section>
    )
}

export default ContentfulBlockVideo;