// extracted by mini-css-extract-plugin
export var root = "HorizontalCarousel-module--root--rUJet";
export var mainContent = "HorizontalCarousel-module--mainContent--h7HuK";
export var singleContent = "HorizontalCarousel-module--singleContent--0qwjB";
export var content = "HorizontalCarousel-module--content--5HV0Q";
export var imageContainer = "HorizontalCarousel-module--imageContainer--UjuOZ";
export var cardContainer = "HorizontalCarousel-module--cardContainer--cFEJ3";
export var titleContainer = "HorizontalCarousel-module--titleContainer--ECYF0";
export var controlPanelContainer = "HorizontalCarousel-module--controlPanelContainer--fEq8P";
export var paginationContainer = "HorizontalCarousel-module--paginationContainer--Jbuvz";
export var active = "HorizontalCarousel-module--active--85lI1";
export var arrow = "HorizontalCarousel-module--arrow--Qtt4w";
export var prevArrow = "HorizontalCarousel-module--prevArrow---ajRh";
export var controlPanel = "HorizontalCarousel-module--controlPanel--kESp+";
export var featuredContainer = "HorizontalCarousel-module--featuredContainer--RzZQ7";
export var arrowPrev = "HorizontalCarousel-module--arrowPrev--uFLvr";