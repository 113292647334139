// extracted by mini-css-extract-plugin
export var pageHero = "ContentfulBlockHero-module--pageHero--REZer";
export var pageHeroImage = "ContentfulBlockHero-module--pageHeroImage--g--VZ";
export var withSubmenu = "ContentfulBlockHero-module--withSubmenu--ezLzA";
export var heroSubmenu = "ContentfulBlockHero-module--heroSubmenu--ox80A";
export var inner = "ContentfulBlockHero-module--inner--aNLLY";
export var stickyTabs = "ContentfulBlockHero-module--stickyTabs--Vqs-o";
export var stickyShow = "ContentfulBlockHero-module--stickyShow--vDbiY";
export var innerSticky = "ContentfulBlockHero-module--innerSticky--Mw39w";
export var contentBlur = "ContentfulBlockHero-module--contentBlur--kBZOn";
export var navSlideDown = "ContentfulBlockHero-module--navSlideDown--S-n9O";
export var active = "ContentfulBlockHero-module--active--Shreo";
export var navSlide = "ContentfulBlockHero-module--navSlide--IDnPR";