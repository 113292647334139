import React, { useState } from 'react';
// import Icon from '../../atoms/Icon/Icon';
import * as styles from './Accordion.module.css';

const Accordion = ({ accordionClasses, titleClasses, bodyClasses, endIcon, showIcon, withBackground, title = null, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // const renderAccordion = () => {
  //   if(mode === 'home' || mode === null) {
  //     return(
  //     <div data-accordion className={`${styles.root} ${mode === 'home' ? styles.withIndicator : ''} ${isOpen ? styles.open : ''}`}>
  //       {titlecomponent ? (
  //         <div className={styles.title} onClick={() => setIsOpen(!isOpen)} role="presentation">{titlecomponent}</div>
  //       ) : (
  //         <h6 className={styles.title} onClick={() => setIsOpen(!isOpen)} role="presentation">{title || 'Heading'}</h6>
  //       )}
  //       <div className={`${styles.body} ${isOpen === true ? styles.openBody : styles.closeBody}`}>
  //         {children}
  //       </div>
  //     </div>);
  //   } else if (mode === 'product_information') {
  //     return(
  //       <div className={`${styles.root} ${isOpen ? styles.open : ''}`}>
  //         <div role="presentation" onClick={() => setIsOpen(!isOpen)} className={styles.titleContainer}>
  //           <span className={styles.title}>{title}</span>
  //           <div className={styles.toggleContainer}>
  //             <Icon symbol={isOpen === false ? 'add' : 'minus'}></Icon>
  //           </div>
  //         </div>
  //         <div className={`${styles.body} ${isOpen === true ? styles.openBody : ''}`}>
  //           {children}
  //         </div>
  //       </div>);
  //   }
  // } 

  // return renderAccordion();

  return (
    <div data-accordion className={`${styles.root} ${showIcon ? styles.withIcon : ''} ${endIcon ? styles.endIcon : styles.startIcon} ${withBackground ? styles.withBackground : ''} ${accordionClasses || ''} ${isOpen ? styles.open : ''}`}>
      <div data-accordiontitle className={`${styles.titleContainer} ${titleClasses || ''}`} onClick={() => setIsOpen(!isOpen)} role="presentation">
        {showIcon && <div className={styles.iconContainer}>
          <span></span>
          <span></span>
        </div>}
        <span className={styles.title}>{title || 'Heading'}</span>
      </div>
      <div data-accordionbody className={`${styles.body} ${bodyClasses || ''} ${!isOpen ? styles.closeBody : ''}`}>
        {children}
      </div>
    </div>
  )
};

export default Accordion;