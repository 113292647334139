import React from 'react'
import { get } from 'lodash';
import Container from '../../atoms/Container/Container';

import HorizontalCarousel from '../../organisms/HorizontalCarousel/HorizontalCarousel';
import * as styles from './ContentfulBlockCarousel.module.css';

const ContentfulBlockCarousel = props => {
    const { 
        size,
        slides,
        title,
        slidesToDisplay,
        hideImageSlidesTitle
        // carouselType,
    } = props;

    // To do: support carouselType and slidesToDisplay
    // note: react-slick has a problem with width being dynamic https://github.com/akiran/react-slick/issues/1241

    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    const normalizedCarouselContent = slides?.map((slide) => {
        return {
            image: slide.image?.file?.url,
            title: slide.name,
            altText: slide.altText,
            id: slide.id,
        }
    })

    return (
        <section className={`${styles.pageCarousel}`} style={mainStyles}>
            <Container size={size}>
                <h5>{title}</h5>
                <HorizontalCarousel 
                    slidesToDisplay={slidesToDisplay} 
                    data={normalizedCarouselContent}
                    hideImageSlidesTitle={hideImageSlidesTitle}/>
                {/* Carousel Code here<br/>
                Carousel Type: {carouselType}<br />
                Title: {title}<br />
                Slides to output here: {slides.length}<br />
                Slides to display in one view: {slidesToDisplay} */}
            </Container>
        </section>
    )
}

export default ContentfulBlockCarousel;