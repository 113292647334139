import React from 'react'
import './PressWall.css';

function PressWall() {
  return (
    <section class="ContentfulBlockPressWall-module--pagePressWall--jjFZo">
        <div class="Container-module--container--ZiuZl Container-module--large--tvzy7">
            <div data-aos="fade-up" data-aos-duration="1000" class="aos-init aos-animate">
                <h3>Seen on</h3>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" class="aos-init aos-animate">
                <div class="ContentfulBlockPressWall-module--presses--Zq41U">
                    <div class="ContentfulBlockPressWall-module--pressItem--eR4PW">
                        <img src="https://images.ctfassets.net/jhh5ec7i1gt8/2OahqfhHCRXQgklaqzUVTH/e65c884eb6e6e05d18b3e8101542f074/FortNine-White.png"
                            alt="Fortnine White" />
                    </div>
                    <div class="ContentfulBlockPressWall-module--pressItem--eR4PW">
                        <img src="https://images.ctfassets.net/jhh5ec7i1gt8/4lB3KZfKn79ptGHazvXmkr/91f12cf2d5c82b8e10cb20fdbe939117/Tech-Crunch-White.png"
                            alt="Tech Crunch White" />
                    </div>
                    <div class="ContentfulBlockPressWall-module--pressItem--eR4PW">
                        <img src="https://images.ctfassets.net/jhh5ec7i1gt8/75S2I6vkj3uO53CUqohvO7/bc1f6b392ad84e38363b5ea2072f7272/Visor-Down-White.png"
                            alt="Visor Down White" />
                    </div>
                    <div class="ContentfulBlockPressWall-module--pressItem--eR4PW">
                        <img src="https://images.ctfassets.net/jhh5ec7i1gt8/47FGRqyzx9ee25Y2iOPL3s/f34b5be462f6b30defed4cae0e7ac02f/Wired-White.png"
                            alt="Wired White" />
                    </div>
                    <div class="ContentfulBlockPressWall-module--pressItem--eR4PW">
                        <img src="https://images.ctfassets.net/jhh5ec7i1gt8/510PFCGV0HYSymjtSAg1AT/b754c91e28a3977bf9453c534c464cbb/Bikesales-White.png"
                            alt="Bikesales White" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default PressWall;