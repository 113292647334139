import React, { useEffect } from 'react';
import './DuelImageMK1SItalian.css';

function DuelImageMK1SItalian() {
  // Function to append a path to the href attribute
  function appendPath(selector, path) {
    const links = document.querySelectorAll(selector);

    links.forEach(link => {
      link.href = path; // Set the link's href to the new path
    });
  }

  useEffect(() => {
    // Example usage: Set the href of links with the specified class to the new URLs
    appendPath('.app-link', 'https://www.forcitehelmets.com/it-it/forcite-app/');
    appendPath('.helmets-link', 'https://www.forcitehelmets.com/it-it/helmets/');
  }, []);

  return (
    <section className="ContentfulBlockDuelImageCtas-module--pageDuelCtas--Vr5Lr">
      <div className="Container-module--container--ZiuZl Container-module--large--tvzy7">
        <div className="ContentfulBlockDuelImageCtas-module--ctaWrapper--DsC1v default aos-init aos-animate" data-aos="fade-up"
          data-aos-duration="1000"><img
            src="https://images.ctfassets.net/jhh5ec7i1gt8/2EKZqZfi4YHgyrcgYVxlxS/0cb05a7e664088e86790423effcb2699/mobile__1_.png"
            alt="Discover the forcite app" /><a href="" className="app-link">&nbsp;</a>
            <div className="ContentfulBlockDuelImageCtas-module--body--9ib8L">
              <h5>SCOPRI L'APP FORCITE</h5>
              <p>Tutta la potenza dell'Ecosistema Forcite a portata di mano</p><span
                className="ContentfulBlockDuelImageCtas-module--cta--RioW6">Scopri di più <svg width="12" height="20"
                  viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1l9 9-9 9" stroke="currentColor" stroke-width="2" fill="none"></path>
                </svg></span>
            </div>
        </div>
        <div className="ContentfulBlockDuelImageCtas-module--ctaWrapper--DsC1v orange aos-init aos-animate" data-aos="fade-up"
          data-aos-duration="1000"><img
            src="https://images.ctfassets.net/jhh5ec7i1gt8/2BJbXnk2Pz40unL4ybMz15/79c2eae5a98632756e56b78fa969a19a/Group_301.svg"
            alt="Configure &amp; Reserve" /><a href="" className="helmets-link">&nbsp;</a>
            <div className="ContentfulBlockDuelImageCtas-module--body--9ib8L">
              <h5>Ordina la tua MK1S</h5>
              <p>Progetta ora il tuo pacchetto MK1S su misura</p><span
                className="ContentfulBlockDuelImageCtas-module--cta--RioW6">Ordina Adesso<svg width="12" height="20"
                  viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1l9 9-9 9" stroke="currentColor" stroke-width="2" fill="none"></path>
                </svg></span>
            </div>
        </div>
      </div>
    </section>
  );
}

export default DuelImageMK1SItalian;
