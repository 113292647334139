import React, { useEffect, useState } from "react"

import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText';
import * as styles from'./SpecsGroup.module.css'

const SpecsGroup = (props) => {
    const { specs } = props;
    
    const [active, setActive] = useState();

    useEffect(() => {
        if(specs !== undefined && specs?.length !== 0) {
            setActive(specs[0].heading)
        }
    }, [specs])

    const attributeList = [];
    return(<div>
        <div className={styles.specificationTitleList}>
            {specs.map((specification, index) => {
                attributeList.push(specification)
                return(
                <h4 className={`${styles.groupName} ${active === specification.heading ? styles.groupActive : ''}`} 
                    onClick={()=>setActive(specification.heading)} 
                    key={`${specification.title}-${index}`}
                    role={'presentation'}>
                    {specification.heading}
                </h4>
                )
            })}
        </div>
        {specs.map((specification, indexSpecs) => {
            return(
            <div key={`${specification.title}-${indexSpecs}`} className={`${styles.specGroupContainer} ${active === specification.heading ? styles.active : ''}`}>
                {specification.content && specification.content.map((atr, index) => {
                    return(<div key={`${atr.heading}-${index}`} className={styles.attributeContainer}>
                            <span className={styles.label}>{atr.heading}</span>
                            <ContentfulRichText raw={atr.copy.raw}></ContentfulRichText>
                    </div>);
                })}
            </div>
            )
        })}
    </div>)
}

export default SpecsGroup
