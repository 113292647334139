import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // Import useHistory from React Router
import './DuelImageMK1S.css';

function DuelImageMK1S() {
  const history = useHistory();

  useEffect(() => {
    // Function to replace the last path segment
    function replaceLastPath(selector, path) {
      const links = document.querySelectorAll(selector);

      links.forEach(link => {
        link.addEventListener('click', event => {
          event.preventDefault();
          const currentHref = link.getAttribute('href');
          const url = new URL(currentHref, window.location.origin);
          const pathSegments = url.pathname.split('/');
          pathSegments.pop(); // Remove the last path segment
          pathSegments.push(path); // Add the new path segment
          const newURL = pathSegments.join('/');
          history.push(newURL); // Use React Router to navigate to the new URL
        });
      });
    }

    replaceLastPath('.app-link', 'forcite-app');
    replaceLastPath('.helmets-link', 'helmets');
  }, [history]);

  return (
    <section class="ContentfulBlockDuelImageCtas-module--pageDuelCtas--Vr5Lr">
        <div class="Container-module--container--ZiuZl Container-module--large--tvzy7">
            <div class="ContentfulBlockDuelImageCtas-module--ctaWrapper--DsC1v default aos-init aos-animate" data-aos="fade-up"
                data-aos-duration="1000"><img
                    src="https://images.ctfassets.net/jhh5ec7i1gt8/2EKZqZfi4YHgyrcgYVxlxS/0cb05a7e664088e86790423effcb2699/mobile__1_.png"
                    alt="Discover the forcite app" /><a href="" className="app-link">&nbsp;</a>
                <div class="ContentfulBlockDuelImageCtas-module--body--9ib8L">
                    <h5>Discover the forcite app</h5>
                    <p>The power of the Forcite Ecosystem at your fingertips</p><span
                        class="ContentfulBlockDuelImageCtas-module--cta--RioW6">Learn More <svg width="12" height="20"
                            viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1l9 9-9 9" stroke="currentColor" stroke-width="2" fill="none"></path>
                        </svg></span>
                </div>
            </div>
            <div class="ContentfulBlockDuelImageCtas-module--ctaWrapper--DsC1v orange aos-init aos-animate" data-aos="fade-up"
                data-aos-duration="1000"><img
                    src="https://images.ctfassets.net/jhh5ec7i1gt8/2BJbXnk2Pz40unL4ybMz15/79c2eae5a98632756e56b78fa969a19a/Group_301.svg"
                    alt="Configure &amp; Reserve" /><a href="" className="helmets-link">&nbsp;</a>
                <div class="ContentfulBlockDuelImageCtas-module--body--9ib8L">
                    <h5>Configure your order</h5>
                    <p>Purchase your own MK1S</p><span
                        class="ContentfulBlockDuelImageCtas-module--cta--RioW6">Shop now <svg width="12" height="20"
                            viewBox="0 0 12 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1l9 9-9 9" stroke="currentColor" stroke-width="2" fill="none"></path>
                        </svg></span>
                </div>
            </div>
        </div>
    </section>
  );
}

export default DuelImageMK1S;