// extracted by mini-css-extract-plugin
export var featuredContainer = "FeaturedReviews-module--featuredContainer--vPC5k";
export var wrapper = "FeaturedReviews-module--wrapper--0ib1W";
export var sliders = "FeaturedReviews-module--sliders--ZBu9n";
export var title = "FeaturedReviews-module--title--B2pae";
export var arrowSlider = "FeaturedReviews-module--arrowSlider--Mn0gE";
export var arrowNext = "FeaturedReviews-module--arrowNext--rZ5jR";
export var arrowPrev = "FeaturedReviews-module--arrowPrev--L8KBu";
export var arrow = "FeaturedReviews-module--arrow--ZrLjj";
export var cardBody = "FeaturedReviews-module--cardBody--6Aqvw";
export var description = "FeaturedReviews-module--description--CR4ne";
export var authorDetails = "FeaturedReviews-module--authorDetails--YDynI";
export var imageSection = "FeaturedReviews-module--imageSection--YDclM";
export var communityBodyDesc = "FeaturedReviews-module--communityBodyDesc--GJAyS";