import React, { useState, useEffect } from 'react';
import './FeatureDetailsItalian.css';

function FeatureDetailsItalian() {

    const [activeAccordions, setActiveAccordions] = useState([true, false, false]); // Initialize the first accordion as open

    const handleAccordionClick = (index) => {
        const newActiveAccordions = [...activeAccordions];
        newActiveAccordions[index] = !newActiveAccordions[index];
        setActiveAccordions(newActiveAccordions);
    };

    // Function to append a path to the href attribute
    function appendPath(selector, path) {
        const links = document.querySelectorAll(selector);

        links.forEach(link => {
        link.href = path; // Set the link's href to the new path
        });
    }

    useEffect(() => {
        // Example usage: Set the href of links with the specified class to the new URLs
        appendPath('.app-link', 'https://www.forcitehelmets.com/it-it/forcite-app/');
        appendPath('.mk1s-link', 'https://www.forcitehelmets.com/it-it/mk1s/');
    }, []);

    return (
        <section class="ContentfulBlockFeatureDetail-module--pageFeatureDetail--onrny">
            <div class="Container-module--container--ZiuZl Container-module--large--tvzy7">
                <div data-aos="fade-up" data-aos-duration="1000" class="aos-init aos-animate">
                    <p class="ContentfulBlockFeatureDetail-module--subtitle--k89ro">ECOSISTEMA</p>
                    <h3>L'ESPERIENZA FORCITE VA OLTRE IL CASCO CHE INDOSSI</h3>
                </div>
                <div class="ContentfulBlockFeatureDetail-module--features--1C-Ip aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                    <div class="ContentfulBlockFeatureDetail-module--featuresImg--uK2q9"><img src="https://images.ctfassets.net/jhh5ec7i1gt8/5LQRMKjF01svoIUa3ZZj7f/a2e0d503f7751543d4d672839a05e25b/Forcite-MKS1-EcoSystem.png" alt="" /></div>
                    <div class="ContentfulBlockFeatureDetail-module--featuresItems--AhK+B">
                        {/* Accordion #1 */}
                        <div data-accordion="true" className={`Accordion-module--root--4uIVH Accordion-module--withIcon--yIHXu Accordion-module--startIcon--lIrFg ${activeAccordions[0] ? 'Accordion-module--open--S1-PM' : ''}`}>
                            <div data-accordiontitle="true" class="Accordion-module--titleContainer--kMA40 active" role="presentation" onClick={() => handleAccordionClick(0)}>
                                <div class="Accordion-module--iconContainer--iKcz7">
                                    <span></span><span></span>
                                </div>
                                <span class="Accordion-module--title--dO3em">Mobile App</span>
                            </div>
                            <div data-accordionbody="true" className={`Accordion-module--body--dCzFY ${activeAccordions[0] ? '' : 'hidden'}`}>
                                <div class="ContentfulBlockFeatureDetail-module--collapseBody--awZ4m">
                                    <p>Il tuo centro di controllo personale del Sistema Forcite. Personalizza e configura la tua espereinza su strada secondo il tuo stile di guida</p>
                                    <p>
                                        <a href="https://play.google.com/store/apps/details?id=com.forcite.app.forcite" class="" target="_blank" rel="noopener noreferrer nofollow">
                                            <img loading="lazy" src="https://images.ctfassets.net/jhh5ec7i1gt8/7K59lwrXrloN7cpzJHKIzY/3f88f44cef3e1ad1c41a1a6827313e28/Google-Play.svg" alt="Get it on Google Play"/>
                                        </a>
                                        <a href="https://apps.apple.com/au/app/forcite/id1519364970" class="" target="_blank" rel="noopener noreferrer nofollow">
                                            <img loading="lazy" height="49" src="https://images.ctfassets.net/jhh5ec7i1gt8/2qrM2JRFdlHl1dZYQ5R2EU/c45141bfae718abbde8d1a08489fa4cf/Apple-Store.svg" alt="Download on the App Store" />
                                        </a>
                                    </p>
                                    <p>
                                        <a class="Button-module--button--tqoAU Button-module--primary--Bb0RT app-link" href="">Approfondisci</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Accordion #2 */}
                        <div data-accordion="true" className={`Accordion-module--root--4uIVH Accordion-module--withIcon--yIHXu Accordion-module--startIcon--lIrFg ${activeAccordions[1] ? 'Accordion-module--open--S1-PM' : ''}`}>
                            <div data-accordiontitle="true" class="Accordion-module--titleContainer--kMA40 active" role="presentation" onClick={() => handleAccordionClick(1)}>
                                <div class="Accordion-module--iconContainer--iKcz7">
                                    <span></span><span></span>
                                </div>
                                <span class="Accordion-module--title--dO3em">Hardware</span>
                            </div>
                            <div data-accordionbody="true" className={`Accordion-module--body--dCzFY ${activeAccordions[1] ? '' : 'hidden'}`}>
                                <div class="ContentfulBlockFeatureDetail-module--collapseBody--awZ4m">
                                    <p>Il casco MK1S + controller Bluetooth risveglia i tuoi sensi, trasformando la tua esperienza di guida</p>
                                    <p></p>
                                    <p><a class="Button-module--button--tqoAU Button-module--primary--Bb0RT mk1s-link" href="">Approfondisci</a></p>
                                    <p></p>
                                </div>
                            </div>
                        </div>

                        {/* Accordion #3 */}
                        <div data-accordion="true" className={`Accordion-module--root--4uIVH Accordion-module--withIcon--yIHXu Accordion-module--startIcon--lIrFg ${activeAccordions[2] ? 'Accordion-module--open--S1-PM' : ''}`}>
                            <div data-accordiontitle="true" class="Accordion-module--titleContainer--kMA40 active" role="presentation" onClick={() => handleAccordionClick(2)}>
                                <div class="Accordion-module--iconContainer--iKcz7">
                                    <span></span><span></span>
                                </div>
                                <span class="Accordion-module--title--dO3em">Controllo Forcite</span>
                            </div>
                            <div data-accordionbody="true" className={`Accordion-module--body--dCzFY ${activeAccordions[2] ? '' : 'hidden'}`}>
                                <div class="ContentfulBlockFeatureDetail-module--collapseBody--awZ4m">
                                    <p>Il nostro sofisticato back-end traduce trilioni di byte di dati critici in informazioni personalizzate sulle condizioni di guida che incontri e in base alle tue preferenze.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FeatureDetailsItalian;
