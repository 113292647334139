import React from 'react'
import { get } from 'lodash';

import * as styles from './ContentfulBlockPressWall.module.css';
import Container from '../../atoms/Container/Container';
import AosInit from '../../atoms/Aos/Aos';

const ContentfulBlockPressWall = props => {
    const { 
        size = 'large',
        name,
        presses,
    } = props;

    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);
    
    const mainStyles = {};

    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    return (
        presses && presses.length > 0 ? <section className={styles.pagePressWall} style={mainStyles}>
            <Container size={size}>
                <AosInit>
                    <h3>{name}</h3>
                </AosInit>
                {presses && 
                    <AosInit>
                        <div className={styles.presses}>
                            {presses.map((press, index) => (
                                <div className={styles.pressItem}>
                                    {press.url ? (
                                        <a href={press.url} title={press.name}>
                                            <img src={press.image && press.image.file.url} alt={press.name} />
                                        </a>
                                    ) : (
                                        <img src={press.image && press.image.file.url} alt={press.name} />
                                    )}
                                </div>
                            ))}
                        </div>
                    </AosInit>
                }
            </Container>
        </section> : <></>
    )
}

export default ContentfulBlockPressWall;