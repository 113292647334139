import React, { useEffect } from 'react';
import './BlockDetailsItalian.css';

function openTab(evt, tabName) {
    // Declare all variables
    var i, tabcontent, tablinks;

  // Get all elements with class="tabcontent" and hide them
  tabcontent = document.querySelectorAll('.SpecsGroup-module--specGroupContainer--hhSQj');
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = 'none';
  }

  // Get all elements with class="tablinks" and remove the class "active"
  tablinks = document.querySelectorAll('.SpecsGroup-module--groupName--tL5Si');
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].classList.remove('active');
  }

  // Show the current tab, and add an "active" class to the button that opened the tab
  document.getElementById(tabName).style.display = 'block';
  evt.currentTarget.classList.add('active');
}

function BlockDetailsItalian() {
    useEffect(() => {
      // Hide content of other tabs when the component mounts
      const tabContents = document.querySelectorAll('.SpecsGroup-module--specGroupContainer--hhSQj');
      tabContents.forEach(tabContent => {
        if (tabContent.id !== 'specifications') {
          tabContent.style.display = 'none';
        }
      });
  
      // Simulate a click event on the 'Specifications' tab when the component mounts
      const specificationsTabButton = document.querySelector('.SpecsGroup-module--groupName--tL5Si[onClick*="specifications"]');
      if (specificationsTabButton) {
        specificationsTabButton.click();
      }
    }, []);
  
    return (
    <section class="ContentfulBlockDetailList-module--pageList--MM7tu">
        <div class="Container-module--container--ZiuZl Container-module--large--tvzy7">
            <div>
                <div class="SpecsGroup-module--specificationTitleList--dHUzq">
                    <h4 class="SpecsGroup-module--groupName--tL5Si active" role="presentation" onClick={(event) => openTab(event, 'specifications')}>Specifications</h4>
                    <h4 class="SpecsGroup-module--groupName--tL5Si " role="presentation" onClick={(event) => openTab(event, 'electronics')}>Electronics</h4>
                </div>

                <div id="specifications" class="SpecsGroup-module--specGroupContainer--hhSQj SpecsGroup-module--active--i0n7t">
                    
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Materiale della calotta esterna</span>
                        <p>La calotta del casco MK1S è realizzata in composito di fibra di carbonio T-400</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Fodera Premium</span>
                        <p>Imbottitura preformata in 3D su tutti i guanciali con fodera della corona che offre una vestibilità sagomata. Il nuovo design del paranuca e sottogola estensibile crea una tenuta più stretta intorno al collo, riducendo significativamente il rumore e fornendo un migliore isolamento per prestazioni audio migliorate. I materiali premium antisudore sono stati selezionati per resistenza, comfort e vestibilità. Questa imbottitura è l'ideale per i clienti con una mentalità premium e per i motociclisti che sperimentano un utilizzo elevato in autostrada.</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Certificazione</span>
                        <p>ECE 22.05 (DOT per i clienti statunitensi)</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Ventilazione</span>
                        <p>Sistema di ventilazione a 8 canali con flusso d'aria ottimizzato</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Visiera</span>
                        <p>Meccanismo di sgancio rapido. Interruttore di blocco per l'utilizzo ad alta velocità. Adtatto per Pinlock Max Vision 120.</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Visiera parasole interna</span>
                        <p>Visiera parasole a scomparsa con classificazione UV400</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Fibbia</span>
                        <p>Sistema ad anello a D</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Weight</span>
                        <p>1500 g ± 50 g (peso XL circa 1630 g)</p>
                    </div>

                </div>

                <div id="electronics" class="SpecsGroup-module--specGroupContainer--hhSQj SpecsGroup-module--active--i0n7t">

                <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Durata batteria Controller bluetooth</span>
                        <p>Circa 4 giorni per ricarica</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Audio</span>
                        <p>Altoparlanti Harman Kardon (40mm) rimovibili in cavità ottimizzata per il confort</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Videocamera</span>
                        <p>Risoluzione 1080P | 30-60 FPS  | H.264 encoding | Sensore da ¼ pollici Sony IMX | Prestazioni ottimali con poca luce</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Ricarica</span>
                        <p>Circa 1.5 ore per ricarica completa via porta USB-C</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Intercom</span>
                        <p>Forcite non fornisce una funzionalità intercom a lungo raggio. La Comunicazione viene gestita via VOIP or chiamata telefonica. La funzionalità Intercom è in fase di sviluppo nel 2024</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Connettività</span>
                        <p>BT 5.0</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Durata batteria Casco</span>
                        <p>3-4 ore con utilizzo intensivo videocamera. Senza registrazione video a app mobile in funzionamento in background la durata è superiore a 7 ore</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Ottica videocamera</span>
                        <p>Ottica con vista diagonale di 158 gradi</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Memoria</span>
                        <p>Micro SD compatible fino a 1TB</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Microfoni</span>
                        <p>Microfoni omnidirezionali settati per chiarezza vocale e riduzione effetti vento</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Salvataggio dati</span>
                        <p>Il salvataggio dei dati video con un intervallo di tempo attraverso l’app (5, 10, 20 minuti)</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Sensori</span>
                        <p>Accellerometro 3DOF integrato per rilevamento sleep mode</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Stabilizzazione</span>
                        <p>Stabilizzazione elettronica per piccole vibrazioni</p>
                    </div>
                    <div class="SpecsGroup-module--attributeContainer--h4iWa"><span
                            class="SpecsGroup-module--label--HTAlL">Display LED periferico</span>
                        <p>Striscia LED con vista periferica diffusa per non distrarre il motociclista con gli alert. Sensori per aggiustare la luminosità a seconda della luce esterna</p>
                    </div>

                </div>
            </div>
        </div>
    </section>
        
  );
}

export default BlockDetailsItalian;