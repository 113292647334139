import React from "react";
import Video from "../../atoms/Video/Video";

import * as styles from './VideoCard.module.css'

const VideoCard = props => {
    return (
        <div className={`${styles.videoCard} ${props.isFeatured ? styles.featuredCard : ''}`}>
            <div className={styles.visual}>
                <Video url={props.videoUrl} splashScreen={props.splashScreen?.file.url} title={props.title} />
            </div>
            <div className={styles.description}>
                <h6>{props.title}</h6>
                {props.isFeatured && (
                    <p>{props.blurb.blurb}</p>
                )}
            </div>
        </div>
    )
}

export default VideoCard;