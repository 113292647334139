import React from "react";

import * as styles from './ContentfulAnimation.module.css';

const ContentfulAnimation = props => {
    const { 
        type,
        images
    } = props;

    switch (type) {
        case 'Universal: Overlay soft fade-in-out':
            return (
                <div className={styles.overlayContinuousFade}>
                    <img src={images[0].image.file.url} alt={images[0].altText} />
                    <img src={images[1].image.file.url} alt={images[1].altText} className={styles.overlay} />
                </div>
            )

        default:
            return null
    }
}

export default ContentfulAnimation;