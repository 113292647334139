import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import FeaturedReviews, { useStyles as styles } from '../../organisms/FeaturedReviews/FeaturedReviews';
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText'
import ContentfulTranslationText from '../../atoms/ContentfulTranslationText/ContentfulTranslationText';


const ContentfulBlockReviews = props => {
    const { 
        title,
        reviews,
    } = props;
    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);
    const supportingImage = get(props, 'supportingImage.file.url', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }

    return (
        <AosInit>
            <FeaturedReviews useChildren={true} title={title} overrideStyles={mainStyles} supportingImage={supportingImage}>
                {reviews && reviews.map((review, index) => (
                  <div key={index} className={styles.cardBody}>
                    <div className={styles.description}>
                        <ContentfulRichText raw={review.review.raw} />
                    </div>
                    {review.author && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="name">Name</ContentfulTranslationText>:</strong> {review.author}</p>)}
                    {review.location && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="location">Location</ContentfulTranslationText>:</strong> {review.location}</p>)}
                    {review.bike && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="bike">Bike</ContentfulTranslationText>:</strong> {review.bike}</p>)}
                    {review.riderType && (<p className={styles.authorDetails}><strong><ContentfulTranslationText keyName="riderType">Rider type</ContentfulTranslationText>:</strong> {review.riderType}</p>)}
                  </div>
                ))}
            </FeaturedReviews>
        </AosInit>
    )
}

export default ContentfulBlockReviews;