import React from "react";
import { get } from 'lodash';
import YouTube from 'react-youtube';

import * as styles from './ContentfulVideo.module.css';

const ContentfulVideo = props => {
    const { 
        title,
        // blurb: {blurb: blurb},
        videoUrl,
    } = props;

    const rawVideoFile = get(props, 'rawVideoFile.file.url', false);
    const splashScreen = get(props, 'splashScreen.file.url', false);

    const initVideo = event => {
        event.target.playVideo();
    }

    if (rawVideoFile) {
        // Uploaded raw file directly
        return (
            <video poster={splashScreen} preload="auto" playsInline autoPlay loop muted>
                <source src={rawVideoFile} type="video/mp4"  />
                Your browser does not support the video tag.
            </video>
        )
    } else if (videoUrl.indexOf('youtube.com') > -1) {
        const youTubeId = videoUrl.split('?v=')[1];

        return (
            <div className={styles.video}>
                {splashScreen && (<img src={splashScreen} alt={title || ''} />)}
                <YouTube videoId={youTubeId} opts={{playerVars: {modestbranding: 1, origin: process.env.SITE_URL}}} onReady={(e) => initVideo(e)} />
            </div>
        )
    }
    
    return null
    
}

export default ContentfulVideo;