import React from 'react'
import { get } from 'lodash';
import ContentfulBlockCarousel from '../../molecules/ContentfulBlockCarousel/ContentfulBlockCarousel';
import ContentfulBlockCodedComponent from '../../molecules/ContentfulBlockCodedComponent/ContentfulBlockCodedComponent';
import ContentfulBlockColumns from '../../molecules/ContentfulBlockColumns/ContentfulBlockColumns';
import ContentfulBlockCopy from '../../molecules/ContentfulBlockCopy/ContentfulBlockCopy';
import ContentfulBlockDuelImageCtas from '../../molecules/ContentfulBlockDuelImageCtas/ContentfulBlockDuelImageCtas'
import ContentfulBlockDetailList from '../../molecules/ContentfulBlockDetailList/ContentfulBlockDetailList';
import ContentfulBlockFeatureDetail from '../../molecules/ContentfulBlockFeatureDetail/ContentfulBlockFeatureDetail'
import ContentfulBlockFeatureIcons from '../../molecules/ContentfulBlockFeatureIcons/ContentfulBlockFeatureIcons'
import ContentfulBlockForm from '../../molecules/ContentfulBlockForm/ContentfulBlockForm';
import ContentfulBlockFullScreenHero from '../../molecules/ContentfulBlockFullScreenHero/ContentfulBlockFullScreenHero';
import ContentfulBlockHero from '../../molecules/ContentfulBlockHero/ContentfulBlockHero';
import ContentfulBlockReviews from '../../molecules/ContentfulBlockReviews/ContentfulBlockReviews';
import ContentfulBlockPageLink from '../../molecules/ContentfulBlockPageLink/ContentfulBlockPageLink';
import ContentfulBlockSideCopy from '../../molecules/ContentfulBlockSideCopy/ContentfulBlockSideCopy';
import ContentfulBlockTestimonials from '../../molecules/ContentfulBlockTestimonials/ContentfulBlockTestimonials';
import ContentfulBlockVideo from '../../molecules/ContentfulBlockVideo/ContentfulBlockVideo';
import ContentfulBlockVisual from '../../molecules/ContentfulBlockVisual/ContentfulBlockVisual';
import ContentfulBlockPressWall from '../../molecules/ContentfulBlockPressWall/ContentfulBlockPressWall';

const ContentfulBlock = ({ block, size }) => {
    const blockType = get(block, 'internal.type');
    
    switch (blockType) {
        case 'ContentfulBlockCarousel':
            return <ContentfulBlockCarousel {...block} size={size} />

        case 'ContentfulBlockCodedComponent':
            return <ContentfulBlockCodedComponent {...block} size={size} />

        case 'ContentfulBlockColumns':
            return <ContentfulBlockColumns {...block} size={size} />

        case 'ContentfulBlockCopy':
            return <ContentfulBlockCopy {...block} size={size} />
        
        case 'ContentfulBlockDetailList':
            return <ContentfulBlockDetailList {...block} size={size}/>

        case 'ContentfulBlockDuelImageCtAs': // Capital A not a typo
            return <ContentfulBlockDuelImageCtas {...block} size={size} />

        case 'ContentfulBlockFeatureDetail':
            return <ContentfulBlockFeatureDetail {...block} size={size} />

        case 'ContentfulBlockFeatureIcons':
            return <ContentfulBlockFeatureIcons {...block} size={size} />

        case 'ContentfulBlockForm':
            return <ContentfulBlockForm {...block} size={size} />

        case 'ContentfulBlockFullScreenHero':
            return <ContentfulBlockFullScreenHero {...block} size={size} />

        case 'ContentfulBlockHero':
            return <ContentfulBlockHero {...block} size={size} />

        case 'ContentfulBlockReviews':
            return <ContentfulBlockReviews {...block} size={size} />

        case 'ContentfulBlockPageLink':
            return <ContentfulBlockPageLink {...block} size={size} />

        case 'ContentfulBlockSideCopy':
            return <ContentfulBlockSideCopy {...block} size={size} />
        
        case 'ContentfulBlockTestimonials':
            return <ContentfulBlockTestimonials {...block} size={size} />

        case 'ContentfulBlockVideo':
            return <ContentfulBlockVideo {...block} size={size} />

        case 'ContentfulBlockVisual':
            return <ContentfulBlockVisual {...block} size={size} />

        case 'ContentfulBlockPressWall':
            return <ContentfulBlockPressWall {...block} size={size} />

        default:
            return null;
    }
}

export default ContentfulBlock;