import React from 'react'
import { get } from 'lodash';
import AosInit from '../../atoms/Aos/Aos';
import Container from '../../atoms/Container/Container';
import Accordion from "../../molecules/Accordion/Accordion"
import ContentfulRichText from '../../atoms/ContentfulRichText/ContentfulRichText'

import * as styles from './ContentfulBlockFeatureDetail.module.css';

const ContentfulBlockFeatureDetail = props => {
    const { 
        size,
        eyebrow,
        title
    } = props;
    const backgroundColor = get(props, 'backgroundColour.hex', false);
    const textColor = get(props, 'backgroundColour.supportingTextColour', false);
    const backgroundImage = get(props, 'backgroundImage.file.url', false);
    const detailImage = get(props, 'detailImage.file.url', false);
    const detailImageDescription = get(props, 'detailImage.description', false);
    const detailContent = get(props, 'detailContent.content', false);

    const mainStyles = {};
    if (backgroundColor) {
        mainStyles.backgroundColor = backgroundColor;
        mainStyles.color = textColor;
    }
    if (backgroundImage) {
        mainStyles.backgroundImage = `url(${backgroundImage})`;
    }

    return (
        <section className={styles.pageFeatureDetail} style={mainStyles}>
            <Container size={size}>
                <AosInit>
                    <p className={styles.subtitle}>{eyebrow}</p>
                    <h3>{title}</h3>
                </AosInit>

                <AosInit classes={styles.features}>
                    <div className={styles.featuresImg}>
                    <img src={detailImage} alt={detailImageDescription} />
                    </div>

                    <div className={styles.featuresItems}>
                        {detailContent && detailContent.map((detail, detailIndex) => (
                            <Accordion key={detailIndex} title={detail.heading} showIcon={true} defaultOpen={detailIndex === 0 ? true : false}>
                                <div className={styles.collapseBody}>
                                    <ContentfulRichText raw={detail.copy.raw} />
                                </div>
                            </Accordion>
                        ))}
                    </div>
                </AosInit>
            </Container>
        </section>
    )
}

export default ContentfulBlockFeatureDetail;